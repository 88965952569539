import React, { useEffect, useContext, memo } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { motion } from "framer-motion";

import { EyeCloseIcon, EyeOpenIcon } from "../../handler/icons";

import heroImage from "../../assets/image/hero-image.jpg";
import khumbuIcon from "../../assets/image/khumbu.png";

import DispatchContext from "../../context/DispatchContext";
import StateContext from "../../context/StateContext";
import ourReducer, {
  initialState,
} from "../../Reducer/checkpointReducer/login";

const cookies = new Cookies();

const CheckPointLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useContext(StateContext);
  const token = appState.credentials.token.trekcard_token;

  useEffect(() => {
    if (!token && location.pathname !== "/cpanel") {
      navigate("/checkpoint");
    } else if (location.pathname === "/cpanel") {
      navigate("/cpanel");
    } else {
      if (appState.credentials.token.trekcard_location == "checkpoint") {
        navigate(
          "/checkpoint/dashboard/" + cookies.get("trekcard_location_id")
        );
      }
      if (appState.credentials.token.trekcard_location == "admin") {
        navigate("/admin/dashboard");
      }
      if (appState.credentials.token.trekcard_location == "entrypoint") {
        navigate("/entrypoint/dashboard");
      }
    }
  }, [token]);

  return (
    <main
      className="checkpost-home"
      style={{
        backgroundImage: `linear-gradient(0deg, #00000088 30%, #ffffff44 100%),url(${heroImage})`,
      }}
    >
      {/* <HomeButton /> */}
      <div className="container">
        <div className="login-body">
          <div className="text-content">
            <motion.h1
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.5 }}
            >
              TREK CARD
            </motion.h1>
            <motion.p
              className="medium-text"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.7 }}
            >
              Trekking Recreation Expedition in Machhapuchhre
            </motion.p>
          </div>
          <div className="form-content">
            <motion.div
              className="text-content mb-5"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logo">
                <img
                  src={khumbuIcon}
                  alt=""
                  onClick={() => navigate("/")}
                  className="rounded-circle zoom-in"
                />
              </div>
              <div className="text-container">
                <p className="medium-bold-text">
                  Machhapuchhre Rural Municipality
                </p>
              </div>
            </motion.div>
            <LoginForm />
            <motion.div
              className="my-2 text-end"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.9 }}
            >
              <Link to="/forgot-password" className="text-hover">
                Forgot password?
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
      <p
        style={{
          fontWeight: "500",
          position: "fixed",
          bottom: "15px",
          right: "35px",
        }}
      >
        Powered by: Affix Link Pvt. Ltd.
      </p>
    </main>
  );
};

const LoginForm = memo(function LoginForm() {
  const navigate = useNavigate();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.submitCount) {
      // const ourRequest = axios.CancelToken.source();
      async function postData() {
        try {
          dispatch({ type: "setPending", value: true });
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/login`,
            {
              email: state.email.value,
              password: state.password.value,
              check_point: state.checkPoint.value,
            }
            // { cancelToken: ourRequest.token }
          );
          if (response.data) {
            // console.log(response.data);
            if (response.data.data.roles[0].id === 2) {
              dispatch({ type: "resetSumbitCount" });
              appDispatch({
                type: "checkPointLogin",
                value: response.data.data,
                id: state.checkPoint.value,
              });
              appDispatch({
                type: "setCurrentCheckpoint",
                id: state.checkPoint.value,
                name: state.checkPoint.name,
              });
              navigate(`/checkpoint/dashboard/${state.checkPoint.value}`);
            } else {
              toast.error("Unauthorized");
            }
          }
          dispatch({ type: "setPending", value: false });
        } catch (error) {
          console.log(error);
          dispatch({ type: "errorOccur" });
          if (error.code === "ERR_NETWORK") {
            toast.error("Network Error");
          } else if (error.response?.data?.data?.error) {
            toast.error(error.response.data.data.error);
          }
          dispatch({ type: "setPending", value: false });
        }
      }
      postData();
    }
  }, [state.submitCount]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    async function getCheckPoint() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/get-check-points`
        );
        if (response) {
          // console.log(response.data);
          appDispatch({ type: "setCheckPoints", value: response.data.data });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
    getCheckPoint();
    return () => ourRequest.cancel();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (!/^\S+@\S+$/.test(state.email.value)) {
      dispatch({ type: "emailError" });
    } else {
      dispatch({ type: "submitForm" });
    }
  }
  return (
    <motion.form
      className="form-container"
      onSubmit={handleSubmit}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ duration: 0.7 }}
    >
      <div className="form-header">
        <h2 className="fw-bold">Checkpoint Login</h2>
      </div>
      <div className="form-body">
        <div className="form-input">
          <label htmlFor="username">Email</label>
          <input
            className="px-2 py-3"
            type="text"
            id="username"
            autoFocus
            placeholder="Type your email"
            onChange={(e) =>
              dispatch({ type: "emailChange", value: e.target.value })
            }
          />
          {state.email.error && (
            <p className="error-message text-danger">{state.email.message}</p>
          )}
        </div>

        <div className="form-input">
          <label htmlFor="password">Password</label>
          <div className="password-input">
            <input
              className="px-2 py-3"
              type={state.type.value ? "text" : "password"}
              id="password"
              placeholder="Type your password"
              onChange={(e) => {
                dispatch({
                  type: "passwordChange",
                  value: e.target.value,
                });
              }}
            />
            <span onClick={() => dispatch({ type: "setType" })}>
              {state.type.value ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </span>
          </div>
        </div>
        <div className="form-input">
          <label htmlFor="">Check Point</label>
          <select
            className="mt-3 mb-0"
            value={state.checkPoint.value || "Select Check Point"}
            onChange={(e) => {
              dispatch({
                type: "checkPointChange",
                value: e.target.value,
                checkpoints: appState.checkpoints,
              });
            }}
          >
            <option disabled>Select Check Point</option>
            {appState.checkpoints &&
              appState.checkpoints.map((item) => {
                return (
                  <option key={item.id} value={item.id} data-name={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="button-container">
        <button className="btn-primary h-25">
          {state.isPending ? "Please wait..." : "LOGIN"}
        </button>
      </div>
    </motion.form>
  );
});

export default CheckPointLogin;
